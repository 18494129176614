/* SNIPPETS */
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_LOADING = "SHOW_LOADING";
export const FETCH_ERROR = "FETCH_ERROR";

/* APP Info */
export const FETCH_APP_INFO_SUCCESS = "FETCH_APP_INFO_SUCCESS";
export const FETCH_APP_INFO_ERROR = "FETCH_APP_INFO_ERROR";

export const IS_FETCHING_SNIPPETS = "IS_FETCHING_SNIPPETS";
export const SET_FETCHING_MESSAGE = "SET_FETCHING_MESSAGE";
export const FETCH_SNIPPETS_SUCCESS = "FETCH_SNIPPETS_SUCCESS";
export const FETCH_SNIPPETS_ERROR = "FETCH_SNIPPETS_ERROR";
export const FETCH_SNIPPET_DETAILS_SUCCESS = "FETCH_SNIPPET_DETAILS_SUCCESS";
export const FETCH_SNIPPET_DETAILS_ERROR = "FETCH_SNIPPET_DETAILS_ERROR";
export const CATEGORIZE_SNIPPETS = "CATEGORIZE_SNIPPETS";
export const FETCH_SNIPPET_IDS_SUCCESS = "FETCH_SNIPPET_IDS_SUCCESS";
export const SEARCH_SNIPPETS = "SEARCHING_SNIPPETS";
export const RESET_FILTERS = "RESET_FILTERS";
export const FILTER_BY_SERVICE = "FILTER_BY_SERVICE";
export const IS_FETCHING_SLACK_CHANNEL_LIST = "IS_FETCHING_SLACK_CHANNEL_LIST";
export const FETCH_SLACK_CHANNELS_ERROR = "FETCH_SLACK_CHANNELS_ERROR";
export const FETCH_SLACK_CHANNELS_SUCCESS = "FETCH_SLACK_CHANNELS_SUCCESS";
export const FETCH_JIRA_DETAILS_ERROR = "FETCH_JIRA_DETAILS_ERROR";
export const FETCH_JIRA_DETAILS_SUCCESS = "FETCH_JIRA_DETAILS_SUCCESS";
export const IS_FETCHING_JIRA_DETAILS = "IS_FETCHING_JIRA_DETAILS";
export const FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS =
  "FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS";
export const FETCH_JIRA_PROJECTS_ISSUE_LIST_ERROR =
  "FETCH_JIRA_PROJECTS_ISSUE_LIST_ERROR";
export const FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS =
  "FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS";
export const FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS =
  "FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS";
export const CLEAR_SELECTED_ISSUE = "CLEAR_SELECTED_ISSUE";
export const FETCH_APP_CONFIG_SUCCESS = "FETCH_APP_CONFIG_SUCCESS";

/* SNIPPET DETAILS */
export const IS_FETCHING_A_SNIPPET = "IS_FETCHING_A_SNIPPET";
export const FETCHING_A_SNIPPET_SUCCESS = "FETCHING_A_SNIPPET_SUCCESS";
export const FETCHING_A_SNIPPET_ERROR = "FETCH_A_SNIPPET_ERROR";
export const UPDATE_SNIPPET_FIELD_OPTIONS = "UPDATE_SNIPPET_FIELD_OPTIONS";
export const SET_SELECTED_SNIPPET = "SET_SELECTED_SNIPPET";

/* RUNBOOKS */
export const FETCH_RUNBOOK_LIST_SUCCESS = "FETCH_RUNBOOK_LIST_SUCCESS";
export const FETCH_RUNBOOK_LIST_ERROR = "FETCH_RUNBOOK_LIST_ERROR";
export const FETCH_RUNBOOK_DETAILS_SUCCESS = "FETCH_RUNBOOK_DETAILS_SUCCESS";
export const FETCH_RUNBOOK_DETAILS_ERROR = "FETCH_RUNBOOK_DETAILS_ERROR";
export const FETCHING_RUNBOOKS = "FETCHING_RUNBOOKS";
export const RUNBOOKS_SORTED = "RUNBOOKS_SORTED";
export const ADD_RUNBOOK_TO_LIST = "ADD_RUNBOOK_TO_LIST";
export const REMOVE_RUNBOOK_FROM_LIST = "REMOVE_RUNBOOK_FROM_LIST";
export const SET_FILTERED_RUNBOOKS = "SET_FILTERED_RUNBOOKS";
export const RESET_LOADING_STATE = "RESET_LOADING_STATE";
export const FETCH_TRIGGERS_SUCCESS = "FETCH_TRIGGERS_SUCCESS";

/* SPECIFIC RUNBOOK */
export const RUNBOOK_SET_BUTTON_ACTIVE = "RUNBOOK_BUTTON_ACTIVE";
export const RUNBOOK_SET_ACTIVE = "RUNBOOK_SET_ACTIVE";
export const RUNBOOK_SET_ACTIVE_VERSION = "RUNBOOK_SET_ACTIVE_VERSION";
export const RUNBOOK_FETCHING_VERSION = "RUNBOOK_FETCH_VERSION";
export const FETCH_RUNBOOK_VERSION_ERROR = "FETCH_RUNBOOK_VERSION_ERROR";
export const FETCH_RUNBOOK_VERSION_SUCCESS = "FETCH_RUNBOOK_VERSION_SUCCESS";
export const RUNBOOK_IS_FETCHING = "RUNBOOK_IS_FETCHING";
export const RUNBOOK_BEFORE_EDITING = "RUNBOOK_BEFORE_EDITING";
export const RUNBOOK_NEW = "RUNBOOK_NEW";
export const RUNBOOK_IS_SAVING = "RUNBOOK_IS_SAVING";
export const RUNBOOK_SAVE_SUCCESS = "RUNBOOK_SAVE_SUCCESS";
export const RUNBOOK_SAVE_ERROR = "RUNBOOK_SAVE_ERROR";
export const RUNBOOK_IS_DELETING = "RUNBOOK_IS_DELETING";
export const UPDATE_RUNBOOK_DATA = "UPDATE_RUNBOOK_DATA";
export const RUNBOOK_UPDATE_TAGS = "RUNBOOK_UPDATE_TAGS";
export const RUNBOOK_DID_UPDATE = "RUNBOOK_DID_UPDATE";
export const RUNBOOK_DELETE_VERSION = "RUNBOOK_DELETE_VERSION";
export const RUNBOOK_DELETE_VERSION_SUCCESS = "RUNBOOK_DELETE_VERSION_SUCCESS";
export const RUBNOOK_FETCH_ERROR = "RUNBOOK_FETCH_ERROR";
export const RUNBOOK_VERSION_UPDATE_SUCCESS = "RUNBOOK_VERSION_UPDATE_SUCCESS";
export const RUNBOOK_VERSION_UPDATE_FAILURE = "RUNBOOK_VERSION_UPDATE_FAILURE";
export const RUNBOOK_DUPLICATE = "RUNBOOK_DUPLICATE";
export const RUNBOOK_SET_EXISTING = "RUNBOOK_SET_EXISTING";
export const RUNBOOK_SCHEDULE = "RUNBOOK_SCHEDULE";
export const RUNBOOK_FETCH_REGION_LIST = "RUNBOOK_FETCH_REGION_LIST";

/* SPECIFIC RUNBOOK --- TERRAFORM*/
export const FETCH_TERRAFORM_WORKSPACE = "FETCH_TERRAFORM_WORKSPACE";
export const FETCH_TERRAFORM_VARIABLES_SUCCESS =
  "FETCH_TERRAFORM_VARIABLES_SUCCESS";
export const FETCH_TERRAFORM_VARIABLES_ERROR =
  "FETCH_TERRAFORM_VARIABLES_ERROR";
export const FETCHING_TERRAFORM_VARIABLES = "FETCHING_TERRAFORM_VARIABLES";

/* SPECIFIC RUNBOOK --- INSTANA*/
export const FETCHING_INSTANA_ENTITY_TYPES = "FETCHING_INSTANA_ENTITY_TYPES";
export const FETCH_INSTANA_ENTITY_TYPES_SUCCESS =
  "FETCH_INSTANA_ENTITY_TYPES_SUCCESS";
export const FETCH_INSTANA_ENTITY_TYPES_ERROR =
  "FETCH_INSTANA_ENTITY_TYPES_ERROR";
export const FETCHING_INSTANA_ENTITY_NAMES = "FETCHING_INSTANA_ENTITY_NAMES";
export const FETCH_INSTANA_ENTITY_NAMES_SUCCESS =
  "FETCH_INSTANA_ENTITY_NAMES_SUCCESS";
export const FETCH_INSTANA_ENTITY_NAMES_ERROR =
  "FETCH_INSTANA_ENTITY_NAMES_ERROR";

/* -- RESOURCES SCANNING */
export const RESOURCES_SCANNED = "RESOURCES_SCANNED";

/* NEW RUNBOOK */
export const RUNBOOK_NAME_AVAILABLE = "RUNBOOK_NAME_AVAILABLE";

/* RUN EXECUTION FOR RUNBOOK */
export const EXECUTION_STEP_FETCH_ERROR = "EXECUTION_STEP_FETCH_ERROR";

/* RUN EXECUTION */
export const EXECUTION_IS_FETCHING = "EXECUTION_IS_FETCHING";
export const EXECUTION_IS_RUNNING = "EXECUTION_IS_RUNNING";
export const EXECUTION_FETCH_SUCCESS = "EXECUTION_FETCH_SUCCESS";
export const EXECUTION_FETCH_ERROR = "EXECUTION_FETCH_ERROR";
export const RUNBOOK_EXECUTION_SUCCESS = "RUNBOOK_EXECUTION_SUCCESS";
export const RUNBOOK_EXECUTION_ERROR = "RUNBOOK_EXECUTION_ERROR";

/* EXEUCTION */
export const EXECUTION_FETCHING_DETAILS = "EXECUTION_FETCHING_DETAILS";
export const EXECUTION_DETAILS_SUCCESS = "EXECUTION_DETAILS_SUCCESS";
export const EXEUCTION_FETCHING_DETAILS = "EXEUCTION_FETCHING_DETAILS";
export const EXECUTION_RESUME_SUCCESS = "EXECUTION_RESUME_SUCCESS";
export const EXECUTION_UPDATE_SEARCH_TERM = "EXECUTION_UPDATE_SEARCH_TERM";
export const EXECUTION_UPDATE_STATUS_FILTER = "EXECUTION_UPDATE_STATUS_FILTER";
export const EXECUTION_UPDATE_TIME_FILTER = "EXECUTION_UPDATE_TIME_FILTER";

/* NODES */
export const ACTIVE_NODE = "ACTIVE_NODE";
export const ACTIVE_LINK = "ACTIVE_LINK";
export const UPDATE_NODE = "UPDATE_NODE";
export const SET_ACTIVE_ACTION_NODE = "SET_ACTIVE_ACTION_NODE";
export const CLEAR_ACTIVE_ACTION_NODE = "CLEAR_ACTIVE_ACTION_NODE";
export const RESET_NODE = "RESET_NODE";

/* ACTION NODES */
export const SERVICES_NEEDED = "SERVICES_NEEDED";
export const IS_FETCHING_SERVICES = "IS_FETCHING_SERVICES";
export const IS_FETCHING_OPERATIONS = "IS_FETCHING_OPERATIONS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_OPERATIONS = "SET_OPERATIONS";
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";
export const SET_SELECTED_OPERATION = "SET_SELECTED_OPERATION";
export const IS_FETCHING_OPERATION_DETAILS = "IS_FETCHING_OPERATION_DETAILS";
export const SET_OPERATION_DETAILS = "SET_OPERATION_DETAILS";
export const SET_OUTPUTS = "SET_OUTPUTS";
export const SET_SELECTED_OUTPUT = "SET_SELECTED_OUTPUT";

/* CONDITIONAL STEPS */
export const SET_CONDITION_STEP = "SET_CONDITION_STEP";

/* UI */
export const FORM_SUBMITTING = "FORM_SUBMITTING";
export const RUNBOOK_NAME_FETCHING = "RUNBOOK_NAME_FETCHING";
export const RUNBOOK_NAME_AVAILABLE_ERROR = "RUNBOOK_NAME_AVAILABLE_ERROR";
export const SHOW_CONFIRM = "SHOW_CONFIRM";
export const SHOW_WAIT = "SHOW_WAIT";
export const SET_WAIT_MESSAGE = "SET_WAIT_MESSAGE";

/* AWS Info for Action Node */
export const AWS_SERVICES_FETCHING = "AWS_SERVICES_FETCHING";
export const AWS_SERVICES_SET = "AWS_SERVICES_SET";
export const AWS_OPERATIONS_FETCHING = "AWS_OPERATIONS_FETCHING";
export const AWS_OPERATIONS_SET = "AWS_OPERATIONS_SET";
export const AWS_OPERATION_DETAILS_SET = "AWS_OPERATION_DETAILS_SET";
export const AWS_SERVICES_SUCCESS = "AWS_SERVICES_SUCCESS";
export const AWS_OPERATIONS_SUCCESS = "AWS_OPERATIONS_SUCCESS";
export const AWS_INFO_ERROR = "AWS_INFO_ERROR";

/* WEBSOCKET */
export const WEBSOCKET_CONNECTION_ID = "WEBSOCKET_CONNECTION_ID";
export const WEBSOCKET_EXECUTION_DETAILS = "WEBSOCKET_EXECUTION_DETAILS";
export const WEBSOCKET_EXECUTION_STEPS = "WEBSOCKET_EXECUTION_STEPS";

/* CONNECTORS */
export const DATADOG_MONITOR_LIST_SET = "DATADOG_MONITOR_LIST_SET";
export const DATADOG_MONITOR_LIST_FETCHING = "DATADOG_MONITOR_LIST_FETCHING";
export const DATADOG_MONITOR_SET = "DATADOG_MONITOR_SET";
export const DATADOG_MONITOR_FETCHING = "DATADOG_MONITOR_FETCHING";
export const DATADOG_MONITOR_ERROR = "DATADOG_MONITOR_ERROR";
export const FETCH_DD_WH_PAYLOAD_SUCCESS = "FETCH_DD_WH_PAYLOAD_SUCCESS";

/* AWS Target Accounts (Get Started Page / AWS Settings List) */
export const AWS_TARGET_ACCOUNTS_FETCH_ERROR =
  "AWS_TARGET_ACCOUNTS_FETCH_ERROR";
export const AWS_TARGET_ACCOUNTS_FETCH_SUCCESS =
  "AWS_TARGET_ACCOUNTS_FETCH_SUCCESS";
export const AWS_TARGET_ACCOUNT_SAVE_SUCCESS =
  "AWS_TARGET_ACCOUNT_SAVE_SUCCESS";
export const AWS_TARGET_ACCOUNT_DELETE = "AWS_TARGET_ACCOUNT_DELETE";
export const AWS_TARGET_ACCOUNT_UPDATE_SUCCESS =
  "AWS_TARGET_ACCOUNT_UPDATE_SUCCESS";
export const AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS =
  "AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS";

/* Settings Page Configuration Panel */

export const CONNECTOR_CONFIGURATION_PANEL_SHOW =
  "CONNECTOR_CONFIGURATION_PANEL_SHOW";
export const CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS =
  "CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS";
export const CONNECTOR_CONFIGURATION_FORM_SAVING =
  "CONNECTOR_CONFIGURATION_FORM_SAVING";
export const CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS =
  "CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS";
export const CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR =
  "CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR";
export const CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS =
  "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS";
export const CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR =
  "CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR";
export const CONNECTORS_FETCH_SUCCESS = "CONNECTORS_FETCH_SUCCESS";
export const CONNECTORS_FETCH_ERROR = "CONNECTORS_FETCH_ERROR";
export const CONNECTOR_STATUS_UPDATE_SUCCESS =
  "CONNECTOR_STATUS_UPDATE_SUCCESS";
export const CONNECTOR_STATUS_UPDATE_ERROR = "CONNECTOR_STATUS_UPDATE_ERROR";
export const FETCH_TERRAFORM_CLI_MODULES_SUCCESS =
  "FETCH_TERRAFORM_CLI_MODULES_SUCCESS";
export const FETCH_TERRAFORM_CLI_MODULES_ERROR =
  "FETCH_TERRAFORM_CLI_MODULES_ERROR";
export const CREATE_TERRAFORM_CLI_MODULE_SUCCESS =
  "CREATE_TERRAFORM_CLI_MODULE_SUCCESS";
export const CREATE_TERRAFORM_CLI_MODULE_ERROR =
  "CREATE_TERRAFORM_CLI_MODULE_ERROR";
export const DELETE_TERRAFORM_CLI_MODULE_SUCCESS =
  "DELETE_TERRAFORM_CLI_MODULE_SUCCESS";
export const DELETE_TERRAFORM_CLI_MODULE_ERROR =
  "DELETE_TERRAFORM_CLI_MODULE_ERROR";
export const UPDATE_TERRAFORM_CLI_MODULE_SUCCESS =
  "UPDATE_TERRAFORM_CLI_MODULE_SUCCESS";
export const UPDATE_TERRAFORM_CLI_MODULE_ERROR =
  "UPDATE_TERRAFORM_CLI_MODULE_ERROR";
export const CONNECTOR_ACTIONS_FETCH_SUCCESS =
  "CONNECTOR_ACTIONS_FETCH_SUCCESS";

/**** CloudFormation begin */
export const FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS =
  "FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS";
export const FETCH_CLOUDFORMATION_TEMPLATE_ERROR =
  "FETCH_CLOUDFORMATION_TEMPLATE_ERROR";
export const CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS =
  "CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS";
export const CREATE_CLOUDFORMATION_TEMPLATE_ERROR =
  "CREATE_CLOUDFORMATION_TEMPLATE_ERROR";
export const DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS =
  "DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS";
export const DELETE_CLOUDFORMATION_TEMPLATE_ERROR =
  "DELETE_CLOUDFORMATION_TEMPLATE_ERROR";
export const UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS =
  "UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS";
export const UPDATE_CLOUDFORMATION_TEMPLATE_ERROR =
  "UPDATE_CLOUDFORMATION_TEMPLATE_ERROR";
/**** CloudFormation begin */

/** Cloudwatch alarm */
export const FETCH_CW_TARGETS = "FETCH_CLOUDWATCH_TARGETS";

/** Schedules */
export const SCHEDULED_RUNBOOKS = "SCHEDULED_RUNBOOKS";
export const IS_FETCHING_SCHEDULES = "IS_FETCHING_SCHEDULES";

/* API Keys */
export const API_KEYS_FETCHING = "API_KEYS_FETCHING";
export const API_KEYS_FETCH_ERROR = "API_KEYS_FETCH_ERROR";
export const API_KEYS_FETCH_SUCCESS = "API_KEYS_FETCH_SUCCESS";
export const API_KEYS_CREATING = "API_KEYS_CREATING";
export const API_KEYS_CREATE_ERROR = "API_KEYS_CREATE_ERROR";
export const API_KEYS_CREATE_SUCCESS = "API_KEYS_CREATE_SUCCESS";
export const API_KEYS_DELETING = "API_KEYS_DELETING";
export const API_KEYS_DELETE_ERROR = "API_KEYS_DELETE_ERROR";
export const API_KEYS_DELETE_SUCCESS = "API_KEYS_DELETE_SUCCESS";
export const API_KEYS_TOGGLE_FIRST_LOGIN = "API_KEYS_TOGGLE_FIRST_LOGIN";
export const API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN =
  "API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN";

/* GIT Repos */
export const GIT_REPOS_FETCH_SUCCESS = "GIT_REPOS_FETCH_SUCCESS";
export const GIT_REPOS_SAVE_SUCCESS = "GIT_REPOS_SAVE_SUCCESS";
export const GIT_REPOS_UPDATE_SUCCESS = "GIT_REPOS_UPDATE_SUCCESS";
export const GIT_REPOS_DELETE_SUCCESS = "GIT_REPOS_DELETE_SUCCESS";

/* SSH Targets */
export const SSH_TARGETS_FETCH_SUCCESS = "SSH_TARGETS_FETCH_SUCCESS";
export const SSH_TARGETS_SAVE_SUCCESS = "SSH_TARGETS_SAVE_SUCCESS";
export const SSH_TARGETS_UPDATE_SUCCESS = "SSH_TARGETS_UPDATE_SUCCESS";
export const SSH_TARGETS_DELETE_SUCCESS = "SSH_TARGETS_DELETE_SUCCESS";

/* Terraform CLI Targets */
export const TERRAFORM_CLI_FETCH_SUCCESS = "TERRAFORM_CLI_FETCH_SUCCESS";
export const TERRAFORM_CLI_SAVE_SUCCESS = "TERRAFORM_CLI_SAVE_SUCCESS";
export const TERRAFORM_CLI_UPDATE_SUCCESS = "TERRAFORM_CLI_UPDATE_SUCCESS";
export const TERRAFORM_CLI_DELETE_SUCCESS = "TERRAFORM_CLI_DELETE_SUCCESS";

/* Account Plan Info */
export const FETCH_QUOTAS_INFO_SUCCESS = "FETCH_QUOTAS_INFO_SUCCESS";
export const FETCH_QUOTAS_INFO_ERROR = "FETCH_QUOTAS_INFO_ERROR";

/* S3 Bucket */
export const S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING =
  "S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING";
export const S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS =
  "S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS";
export const S3_BUCKET_SELECTED_BUCKETS_FETCH_SUCCESS =
  "S3_BUCKET_SELECTED_BUCKETS_FETCH_SUCCESS";

/* Twilio */
export const IS_FETCHING_SENDER_NUMBERS = "IS_FETCHING_SENDER_NUMBERS";
export const FETCH_SENDER_NUMBERS_ERROR = "FETCH_SENDER_NUMBERS_ERROR";
export const FETCH_SENDER_NUMBERS_SUCCESS = "FETCH_SENDER_NUMBERS_SUCCESS";

/* EKS Actions */
export const EKS_ACTIONS_FETCH_SUCCESS = "EKS_ACTIONS_FETCH_SUCCESS";
export const EKS_ACTION_ROLE_DELETE_SUCCESS = "EKS_ACTION_ROLE_DELETE_SUCCESS";

/* Task Management */
export const TM_FETCH_TASK_LIST_SUCCESS = "TM_FETCH_TASK_LIST_SUCCESS";
export const TM_SET_SELECTED_TASK = "TM_SET_SELECTED_TASK";
export const TM_FETCH_TASK_SUCCESS = "TM_FETCH_TASK_SUCCESS";
export const TM_SET_TASK_LIST = "TM_SET_TASK_LIST";
export const TM_FETCH_TASK_TYPE_LIST_SUCCESS =
  "TM_FETCH_TASK_TYPE_LIST_SUCCESS";

/* Task Type Assignment */
export const TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS =
  "TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS";
export const TASK_TYPE_ASSIGNMENT_UPDATE_SUCCESS =
  "TASK_TYPE_ASSIGNMENT_UPDATE_SUCCESS";
export const TASK_TYPE_ASSIGNMENT_DELETE_SUCCESS =
  "TASK_TYPE_ASSIGNMENT_DELETE_SUCCESS";

/* Global Loader */
export const REQUEST_SENT = "REQUEST_SENT";
export const RESPONSE_RECEIVED = "RESPONSE_RECEIVED";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const RESET_STATE = "RESET_STATE";

export const SET_SELECTED_TRIGGER_FOR_WF = "SET_SELECTED_TRIGGER_FOR_WF";

/* Common Reducer */
export const CACHE_ALL_SNIPPETS = "CACHE_ALL_SNIPPETS";
export const REMOVE_CACHED_SNIPPETS = "REMOVE_CACHED_SNIPPETS";
export const CACHE_ACTIONS = "CACHE_ACTIONS";
export const REMOVE_CACHED_ACTIONS = "REMOVE_CACHED_ACTIONS";
export const UPDATE_RECORDS_CURRENT_PAGE = "UPDATE_RECORDS_CURRENT_PAGE";
export const UPDATE_RECORDS_PER_PAGE = "UPDATE_RECORDS_PER_PAGE";
export const UPDATE_SORT_FIELD = "UPDATE_SORT_FIELD";
export const CACHED_AWS_SERVICES = "CACHED_AWS_SERVICES";
export const CACHED_AWS_EC2_OPERATIONS = "CACHED_AWS_EC2_OPERATIONS";
export const REMOVE_CACHED_AWS_DATA = "REMOVE_CACHED_AWS_DATA";

/* Collaboration */
export const FETCH_WORKSPACE_USERS_LIST_SUCCESS =
  "FETCH_WORKSPACE_USERS_LIST_SUCCESS";
export const FETCH_JIRA_PROJECTS_LIST_SUCCESS =
  "FETCH_JIRA_PROJECTS_LIST_SUCCESS";
export const FETCH_ISSUE_TYPES_LIST_SUCCESS = "FETCH_ISSUE_TYPES_LIST_SUCCESS";
export const FETCH_COLLABORATION_DETAILS_SUCCESS =
  "FETCH_COLLABORATION_DETAILS_SUCCESS";
export const UPDATE_COLLABORATION_DETAILS_SUCCESS =
  "UPDATE_COLLABORATION_DETAILS_SUCCESS";
export const CREATE_JIRA_ISSUE_SUCCESS = "CREATE_JIRA_ISSUE_SUCCESS";
export const CREATE_SLACK_CHANNEL_SUCCESS = "CREATE_SLACK_CHANNEL_SUCCESS";
export const CREATE_ZOOM_LINK_SUCCESS = "CREATE_ZOOM_LINK_SUCCESS";

export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";
