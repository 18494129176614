import produce from "immer";
import {
  EXECUTION_UPDATE_SEARCH_TERM,
  EXECUTION_UPDATE_STATUS_FILTER,
  EXECUTION_UPDATE_TIME_FILTER,
  UPDATE_RECORDS_PER_PAGE,
  UPDATE_RECORDS_CURRENT_PAGE,
  UPDATE_SORT_FIELD,
  SET_SELECTED_TRIGGER_FOR_WF,
  FETCH_TRIGGERS_SUCCESS,
  CACHE_ALL_SNIPPETS,
  REMOVE_CACHED_SNIPPETS,
  CACHE_ACTIONS,
  REMOVE_CACHED_ACTIONS,
  SET_SELECTED_ORGANIZATION,
  REMOVE_CACHED_AWS_DATA,
  CACHED_AWS_SERVICES,
  CACHED_AWS_EC2_OPERATIONS,
} from "@redux/types";
import { defaultExecutionTimeFilter } from "@lib/utils/constants";

const INITIAL_AWS_STATE = {
  services: [],
  ec2Operations: [],
  awsDocLink: null,
  ec2ServiceDocLink: null,
};

const INITIAL_STATE = {
  executionSearchTerm: "",
  executionStatusFilter: [],
  executionTimeFilter: defaultExecutionTimeFilter,
  recordsPerPage: {
    taskTypeAssignRecords: 10,
    taskTypesRecords: 10,
    snippetsRecords: 15,
    executionsRecords: 10,
    schedulesRecords: 10,
    workflowsRecords: 10,
    taskManageRecords: 10,
    allExecutionsRecords: 10,
    environmentHealthRecords: 10,
  },
  currentPages: {
    taskTypeAssignCPage: 1,
    taskTypesCPage: 1,
    snippetsCPage: 1,
    executionsCPage: 1,
    schedulesCPage: 1,
    workflowsCPage: 1,
    taskManageCPage: 1,
    allExecutionsCPage: 1,
    environmentHealthCPage: 1,
  },
  sortingDetails: {
    taskTypes: { sortField: "", sortFieldDirection: "asc" },
    snippets: { sortField: "", sortFieldDirection: "asc" },
    executions: { sortField: "", sortFieldDirection: "asc" },
    schedules: { sortField: "", sortFieldDirection: "asc" },
    workflows: { sortField: "", sortFieldDirection: "asc" },
    taskManage: { sortField: "", sortFieldDirection: "asc" },
    allExecutions: { sortField: "", sortFieldDirection: "asc" },
  },
  selectedTrigger: null,
  triggerList: null,
  cachedSnippets: null,
  getStartedActionsMap: {},
  selectedOrganization: null,
  aws: INITIAL_AWS_STATE,
};

const commonReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case EXECUTION_UPDATE_SEARCH_TERM:
        draft.executionSearchTerm = action.payload;
        break;

      case EXECUTION_UPDATE_STATUS_FILTER:
        draft.executionStatusFilter = action.payload;
        break;

      case EXECUTION_UPDATE_TIME_FILTER:
        draft.executionTimeFilter = action.payload;
        break;

      case UPDATE_RECORDS_PER_PAGE:
        if (draft.recordsPerPage) {
          draft.recordsPerPage[action.payload.tableName] =
            action.payload.cPageRecords;
        }
        break;

      case UPDATE_RECORDS_CURRENT_PAGE:
        if (draft.currentPages) {
          draft.currentPages[action.payload.tableName] = action.payload.cPage;
        }
        break;

      case UPDATE_SORT_FIELD:
        if (draft.sortingDetails) {
          draft.sortingDetails[action.payload.tableName].sortField =
            action.payload.sortField;
          draft.sortingDetails[action.payload.tableName].sortFieldDirection =
            action.payload.sortFieldDirection;
        }
        break;

      case SET_SELECTED_TRIGGER_FOR_WF:
        draft.selectedTrigger = action.payload;
        break;

      case FETCH_TRIGGERS_SUCCESS:
        draft.triggerList = action.payload;
        break;

      case CACHE_ALL_SNIPPETS:
        draft.cachedSnippets = action.payload;
        break;

      case REMOVE_CACHED_SNIPPETS:
        draft.cachedSnippets = null;
        break;

      case CACHE_ACTIONS:
        const { connector, data } = action.payload;
        draft.getStartedActionsMap[connector] = data;
        break;

      case REMOVE_CACHED_ACTIONS:
        draft.getStartedActionsMap = {};
        break;

      case SET_SELECTED_ORGANIZATION:
        draft.selectedOrganization = action.payload;
        break;
        
      case CACHED_AWS_SERVICES:
        draft.aws.services = action.payload?.services || [];
        draft.aws.awsDocLink = action.payload?.documentation || null;
        break;

      case CACHED_AWS_EC2_OPERATIONS:
        draft.aws.ec2Operations = action.payload?.operations || [];
        draft.aws.ec2ServiceDocLink = action.payload?.documentation || "";
        break;

      case REMOVE_CACHED_AWS_DATA:
        draft.aws = INITIAL_AWS_STATE;
        break;

      default:
        break;
    }
  });

export default commonReducer;
