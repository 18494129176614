import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import { isEmpty, sortResourceList } from "@lib/utils";
import { getTaskTypeAssignment } from "@redux/actions/taskTypeAssignment.action";
import { fetchAllTaskTypes } from "@redux/actions/taskManagement.actions";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import Api from "@lib/api";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { FTNotification, Modal } from "@components/ui";
import { withRouter } from "react-router";
import {
  prepareUrl,
  shouldShowField,
  prepareValidationSchema,
  getOptions,
  getPayload,
} from "./TaskTypeAssignmentHelper";
import { taskPrioritiesList } from "@lib/utils/constants";

type ManageTaskTypeAssignmentProps = {
  currentNodeSchema: any;
  setToggleModal: (flag: boolean) => void;
  connector: string;
  taskTypesList: any;
  getTaskTypeAssignment: (connector: string) => void;
  fetchAllTaskTypes: () => void;
};

type ManageTaskTypeAssignmentStateProps = {
  showForm: boolean;
  initialValues: any;
  validationSchema: any;
  fieldOptions: any;
  taskTypeLabel: string;
  taskTypeObj: any;
};
class ManageTaskTypeAssignment extends Component<
  ManageTaskTypeAssignmentProps,
  ManageTaskTypeAssignmentStateProps
> {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      initialValues: {},
      validationSchema: {},
      fieldOptions: {},
      taskTypeLabel: "Please select the Corresponding Incident Type",
      taskTypeObj: null,
    };
  }

  /**
   * LIFE CYCLE METHODS
   */
  async componentDidMount() {
    if (!(this.props.taskTypesList.length > 0)) this.props.fetchAllTaskTypes();
    !isEmpty(this.props.currentNodeSchema) && this.getInitialValues();
  }

  /**
   * Function to prepare options for dependent/independent dropdowns
   * and save them in state object "fieldOptions"
   * @param formSchema Formik form schema
   * @param param1 Dependencies object spreaded from connector schema
   */
  prepareFieldOptionList = async (formSchema, formikValues) => {
    const fieldOptions = {};
    for (const fieldKey in formSchema) {
      const input = formSchema[fieldKey];
      if (!fieldOptions.hasOwnProperty(fieldKey)) {
        const dependencies = input.dependencies;
        /**
         * @case Input values has ExternalAPI object
         */
        if (input.hasOwnProperty("external_api")) {
          const externalApi = input.external_api.endpoint;
          try {
            let response = await Api._getData("", {}, externalApi);
            response =
              typeof response === "string" ? JSON.parse(response) : response;
            fieldOptions[fieldKey] = response;
          } catch (error) {
            console.log(error);
          }
        }
        /**
         * @case Input values has API object
         */
        if (
          input.hasOwnProperty("api") &&
          shouldShowField(input, formikValues)
        ) {
          let { endpoint, key, params } = input.api;
          /**
           * @case Values are static and to be fetched from existing key-value pair.
           * Ex - SplunkOnCall Team-Policies
           */
          if (endpoint === "static") {
            //logic for preparing option list from parents api response in this.state.fieldOptions
            const dependency = dependencies[0];
            const dependentOptions = fieldOptions[dependency];
            fieldOptions[fieldKey] = dependentOptions;
            continue;
          }
          /**
           * @case Values are to be fetched from API endpoint
           */
          try {
            const finalEndPoint = prepareUrl(endpoint, params, formikValues);
            const response = await Api._getData(finalEndPoint);
            /**
             * handle one-off case for Alias field where response is MapList
             */
            if (fieldKey === "alias") {
              fieldOptions[fieldKey] = sortResourceList(response);
            } else {
              let options = response?.body
                ? typeof response?.body === "string"
                  ? JSON.parse(response.body)
                  : response.body
                : response;

              // If key is present, assign the MapList assigned against key in response body
              if (key) {
                options = options[key];
              }
              fieldOptions[fieldKey] = options;
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    return { ...this.state.fieldOptions, ...fieldOptions };
  };

  /**
   * @function getInitialValues Function to initialize the Form Fields
   */
  getInitialValues = async () => {
    let initialValues = {};
    let formSchema = { ...this.props.currentNodeSchema };
    Object.keys(formSchema).forEach(val => (initialValues[val] = ""));
    initialValues["taskTypeId"] = "";
    initialValues["priority"] = "";
    initialValues["assignee"] = "";

    let fieldOptions = await this.prepareFieldOptionList(
      formSchema,
      initialValues,
    );

    const validationSchema = prepareValidationSchema(initialValues, formSchema);
    this.setState({ initialValues, fieldOptions, validationSchema }, () => {
      this.setState({ showForm: true });
    });
  };

  /**
   * Function to prepare Form Fields
   * @param param0 Formik values spreaded
   * @param param1 Formik touched method spreaded
   * @param param2 Formik errors object spreaded
   * @param param3 Formik method to set field value spreaded
   */
  getFormFields = ({ values, touched, errors, setFieldValue }) => {
    const excludeFields = ["taskTypeId", "priority", "assignee"];

    return (
      !isEmpty(this.state.initialValues) &&
      Object.keys(this.state.initialValues)
        .filter(key => !excludeFields.includes(key))
        .map((field, key) => {
          const props = {};
          for (const key in this.props.currentNodeSchema) {
            props[key] = { ...this.props.currentNodeSchema[key] };
            props[key]["name"] = key;
          }
          const { display_name, input_option, api, dependencies } = props[
            field
          ];

          let fieldValue = values[field];
          const updatedValues = { ...values };

          try {
            fieldValue = JSON.parse(values[field]);
          } catch (err) {}

          if (!shouldShowField(props[field], values)) {
            return null;
          }

          switch (input_option) {
            case "textarea":
              return (
                <FormInput
                  fieldName={display_name}
                  name={field}
                  showJson={true}
                  id={field}
                  fieldValue={fieldValue}
                  touched={touched}
                  errors={errors}
                  placeholder={`Enter ${display_name}`}
                  key={key}
                  autoComplete="new-password"
                  as={`textarea`}
                />
              );

            case "singleselect":
            case "dropdown":
              const dependency = dependencies?.[0] || "";
              const dropdownOptions = getOptions(
                api,
                dependency,
                values,
                this.state.fieldOptions[field],
              );
              // eslint-disable-next-line eqeqeq
              const dropdownDisplayLabel =
                (dropdownOptions?.length &&
                  dropdownOptions.find(
                    o => o.value.toString() === fieldValue.toString(),
                  )?.label) ||
                "Select from below";
              return (
                <div className="gs-panel-right-field-wrapper" key={key}>
                  <label className="label mb-1">{display_name}</label>
                  <ReactSelect
                    id="webhook_api_key_name"
                    name="webhook_api_key_name"
                    value={{
                      value: fieldValue || "",
                      label: dropdownDisplayLabel,
                    }}
                    handleChange={async data => {
                      if (data === null) {
                        setFieldValue(field, "");
                      } else {
                        const newValue = data.value;
                        if (fieldValue !== newValue) {
                          /**
                           * @param name Name of input
                           * @param api API object in snippetDef
                           */

                          setFieldValue(field, newValue);
                          updatedValues[field] = newValue;
                          const dI = Object.values(props).filter(
                            (val: any) =>
                              val.dependencies?.length === 1 &&
                              val.dependencies.includes(field),
                          ) as any;
                          const fieldOptions = { ...this.state.fieldOptions };
                          let promises = [];
                          dI.forEach(dependentInput => {
                            if (
                              dependentInput &&
                              !isEmpty(dependentInput?.api)
                            ) {
                              const {
                                endpoint,
                                params,
                                selector,
                                use_key_as_dependent,
                              } = dependentInput.api;
                              if (endpoint === "static") {
                                let options = this.state.fieldOptions[field];
                                /**
                                 * @case 1 - Dependent field is dropdown, ex - splunk-on-call
                                 * @case 2 - Dependent field is text, ex - webhook api key name - value
                                 */
                                if (
                                  dependentInput.input_option === "dropdown" ||
                                  dependentInput.input_option === "singleselect"
                                ) {
                                  fieldOptions[dependentInput.name] = options;
                                  this.setState({ fieldOptions });
                                } else {
                                  const depFieldVal = options.find(
                                    v =>
                                      v[fieldValue.api["selector"]] ===
                                      newValue,
                                  )[selector];
                                  setFieldValue(
                                    dependentInput.name,
                                    depFieldVal,
                                  );
                                  updatedValues[
                                    dependentInput.name
                                  ] = depFieldVal;
                                }
                              } else {
                                setFieldValue(dependentInput.name, "");
                                updatedValues[dependentInput.name] = "";
                                const finalEndPoint = prepareUrl(
                                  endpoint,
                                  params,
                                  updatedValues,
                                  dropdownOptions,
                                  use_key_as_dependent,
                                );
                                promises.push(Api._getData(finalEndPoint));
                              }
                            }
                          });

                          try {
                            let responseArr = await Promise.all(promises);
                            responseArr.forEach((response, i) => {
                              const key = dI[i]["api"]["key"];
                              let options = response.body
                                ? typeof response?.body === "string"
                                  ? JSON.parse(response.body)
                                  : response.body
                                : response;
                              // If key is present, assign the MapList assigned against key in response body
                              if (key) {
                                options = options[key];
                              }
                              fieldOptions[dI[i].name] = options;
                            });
                            this.setState({ fieldOptions });
                          } catch (e) {
                            console.log(e);
                          }
                        }
                      }
                    }}
                    selectOptions={dropdownOptions}
                    customMenuClass="default-select-options-container"
                    customMenuListClass="default-select-options-list"
                    customValueContainerClass="default-select-value-container"
                    customControlClass="default-select-control"
                    customOptionClass="default-select-list-item"
                    required
                  />
                  {errors[field] && touched[field] && (
                    <div className="input-feedback">{errors[field]}</div>
                  )}
                </div>
              );

            case "toggle":
              const toggleValue = fieldValue === "ON" ? true : false;
              return (
                <div className="mb-2" key={key}>
                  <div className="enable-notifications-toggle">
                    <label className="label mb-0">{display_name}</label>
                    <>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name={field}
                          id={field}
                          checked={toggleValue}
                          onChange={() => {
                            const updatedToogle = !toggleValue;
                            const status = updatedToogle ? "ON" : "OFF";
                            setFieldValue(field, status);
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                    </>
                  </div>
                </div>
              );

            case "text":
            default:
              return (
                <FormInput
                  fieldName={display_name}
                  name={field}
                  showJson={true}
                  id={field}
                  fieldValue={fieldValue}
                  touched={touched}
                  errors={errors}
                  placeholder={`Enter ${display_name}`}
                  key={key}
                  autoComplete="new-password"
                />
              );
          }
        })
    );
  };

  createTaskTypeAssignment = async (taskTypeId, payload) => {
    await Api.createTaskTypeAssignment(taskTypeId, payload);
    this.props.getTaskTypeAssignment(this.props.connector);
    FTNotification.success({
      title: "Incident Type Assignment Saved Successfully!",
    });
    this.props.setToggleModal(false);
  };

  /**
   * Function to submit the payload
   * @param values Formik values object
   * @param actions Formik actions object
   */
  onSubmitHandle = async (values, actions) => {
    try {
      let { taskTypeId } = values;
      let payload = getPayload(values, this.props.connector);

      if (!this.state.taskTypeObj?.meta.hasOwnProperty("workflow")) {
        /**
         * If there is no workflow attached to the Task Type,
         * user can proceed to add the task type assignment.
         */
        this.createTaskTypeAssignment(taskTypeId, payload);
      } else {
        let tags = await Api.fetchRunbookTags(
          this.state.taskTypeObj?.meta["workflow"].name,
        );
        if (
          tags?.Tags.includes("trigger:manual") ||
          !tags?.Tags.some(tag => tag.startsWith("trigger"))
        ) {
          /**
           * If workflow does not have trigger:xxx or has a trigger:manual tag,
           * then user can proceed to add task type assignment
           */
          this.createTaskTypeAssignment(taskTypeId, payload);
        } else {
          if (!tags?.Tags.includes(`trigger:${this.props.connector}`)) {
            /**
             * If workflow has trigger:xxx and xxx doesn't matche with connector,
             * then prompt user to pick another Task Type
             */
            actions.setStatus({
              formSubmitMessage:
                "The workflow you selected for this incident type assignment does not include the same integration trigger. Please make sure to match the trigger connector with this integration where you intend to place the incident type assignment. </br>For example, Datadog trigger in the workflow should match the Datadog integration incident type assignment.",
            });
          } else {
            this.createTaskTypeAssignment(taskTypeId, payload);
          }
        }
      }
    } catch (error) {
      console.log(error);
      FTNotification.error({
        title: "Could not save Incident Type Assignment!",
        message: (error as any).message,
      });
    }
  };
  render() {
    return (
      <>
        {this.state.showForm && (
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={this.state.validationSchema}
            onSubmit={this.onSubmitHandle}
          >
            {formik => (
              <Modal
                onClose={() => this.props.setToggleModal(false)}
                title="Add New Assignment"
                onCancel={() => {
                  this.props.setToggleModal(false);
                }}
                onSubmit={formik.handleSubmit}
                submitButtonText="Add Assignment"
                showClose={true}
                backgroundCanClose={false}
                contentClass="task-assignment-content"
                contentContainerClass="task-assignment-content-container"
              >
                {this.getFormFields(formik)}
                <label className="label mt-20-px">
                  Corresponding Incident Type
                </label>
                <ReactSelect
                  id="taskTypeId"
                  name="taskTypeId"
                  value={{
                    value: formik.values.taskTypeId,
                    label: this.state.taskTypeLabel,
                  }}
                  handleChange={data => {
                    if (data === null) {
                      formik.setFieldValue("taskTypeId", "");
                      this.setState({
                        taskTypeLabel: "",
                        taskTypeObj: "",
                      });

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    } else if (!isEmpty(data) && !!data.value && !!data.label) {
                      formik.setFieldValue("taskTypeId", data.value);
                      this.setState({
                        taskTypeLabel: data.label,
                        taskTypeObj: data.obj,
                      });

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    }
                  }}
                  selectOptions={this.props.taskTypesList.map(item => {
                    return {
                      obj: item,
                      label: item?.meta?.name || "",
                      value: item.TaskTypeId,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                />
                {formik.errors["taskTypeId"] &&
                  formik.touched["taskTypeId"] && (
                    <div className="input-feedback">
                      {formik.errors["taskTypeId"]}
                    </div>
                  )}
                <label className="label mt-20-px">Priority</label>
                <ReactSelect
                  id="priority"
                  name="priority"
                  value={{
                    value: formik.values.priority || "",
                    label: formik.values.priority || "",
                  }}
                  handleChange={data => {
                    if (data === null) {
                      formik.setFieldValue("priority", "");

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    } else if (!isEmpty(data) && !!data.value && !!data.label) {
                      formik.setFieldValue("priority", data.value);

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    }
                  }}
                  selectOptions={taskPrioritiesList.map(item => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                />
                {formik.errors["priority"] && formik.touched["priority"] && (
                  <div className="input-feedback">
                    {formik.errors["priority"]}
                  </div>
                )}
                <label className="label mt-20-px">
                  Assign Incident Owner (Optional)
                </label>
                <ReactSelect
                  id="assignee"
                  name="assignee"
                  value={{
                    value: formik.values.assignee || "",
                    label: formik.values.assignee || "",
                  }}
                  handleChange={data => {
                    if (data === null) {
                      formik.setFieldValue("assignee", "");

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    } else if (!isEmpty(data) && !!data.value && !!data.label) {
                      formik.setFieldValue("assignee", data.value);

                      formik.setStatus({
                        formSubmitMessage: "",
                      });
                    }
                  }}
                  selectOptions={[
                    "User 1",
                    "User 2",
                    "User 3",
                    "User 4",
                    "User 5",
                  ].map(item => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                />
                {formik.errors["assignee"] && formik.touched["assignee"] && (
                  <div className="input-feedback">
                    {formik.errors["assignee"]}
                  </div>
                )}
                <div
                  className="input-feedback pt-2"
                  dangerouslySetInnerHTML={{
                    __html:
                      !!formik.status && formik.status.formSubmitMessage
                        ? formik.status.formSubmitMessage
                        : "",
                  }}
                ></div>
              </Modal>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  taskTypesList: state.taskManagementReducer.allTaskTypesList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTaskTypeAssignment,
      fetchAllTaskTypes,
    },
    dispatch,
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageTaskTypeAssignment),
);
