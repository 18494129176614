//import useState hook to create menu collapse state
import React, { useState, useEffect } from "react";
import { getUrlParameter } from "@lib/utils";
import { Link, useHistory } from "react-router-dom";
import warningSign from "@assets/images/icons/warning-triangle.svg";
import Version from "./utils/Version";
import { useSelector, useDispatch } from "react-redux";
import { capitalize } from "lodash";
import { RouteConstants } from "../../routes/Constants";
import Api from "@lib/api";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import Avatar from "react-avatar";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import ReactTooltip from "react-tooltip";
import FylamyntLogo from "@assets/images/logo/fylamynt-logo.svg";
import FylamyntTextLogo from "@assets/images/logo/fylamynt-text-logo.svg";
import "react-pro-sidebar/dist/css/styles.css";
import "./LeftMenu.scss";

const supportMenus = {
  getStarted: {
    display: "Get Started",
    url: "/user-onboarding",
    baseUrl: "user-onboarding",
    internalLink: true,
  },
  newRelease: {
    display: "What's new?",
    url: "https://docs.fylamynt.com/announcement/whats-new",
  },
  support: {
    url: "https://docs.fylamynt.com/support/contact-fylamynt", //"https://www.fylamynt.com/",
    display: "Support",
    className: "icon icon__external",
  },
  tutorials: {
    url: "https://docs.fylamynt.com",
    display: "Documentation",
    className: "icon icon__external",
  },
};

const menus = {
  dashboard: {
    url: "/dashboard/incidents",
    baseUrl: "dashboard",
    display: "Dashboard",
    icon: "dashboard",
  },
  runbooks: {
    url: "/workflows/my-workflows",
    baseUrl: "workflows",
    secondaryUrl: "workflow",
    display: "Workflows",
    icon: "runbooks",
  },
  Incidents: {
    url: "/incidents",
    baseUrl: "incidents",
    display: "Incidents",
    icon: "incident",
  },
  snippets: {
    url: "/actions",
    baseUrl: "actions",
    display: "Actions",
    icon: "snippets",
  },
  executions: {
    url: "/executions",
    baseUrl: "executions",
    display: "Executions",
    icon: "executions",
  },
  schedules: {
    url: "/schedules",
    baseUrl: "schedules",
    display: "Schedules",
    icon: "calendar",
  },
  settings: {
    url: "/settings/integrations",
    baseUrl: "settings",
    display: "Settings",
    icon: "settings",
  },
};

const LeftMenu = () => {
  const history = useHistory();
  const active = getUrlParameter(0);
  const dispatch = useDispatch();
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  const quotas = useSelector(state => state.accountPlanReducer.quotas);

  const user = useSelector(state => state.userReducer);

  // Quota plans used for internal users only
  const INTERNAL_PLANS = ["free", "devtest", "internal"];

  useEffect(() => {
    if (!quotas) dispatch(fetchAccountPlanInfo());
  }, [dispatch, quotas]);
  const [menuCollapse, setMenuCollapse] = useState(true);

  //Custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  const managePlan = async () => {
    const currentPlan = quotas?.current_tier;
    if (!currentPlan) {
      return;
    }

    if (INTERNAL_PLANS.includes(currentPlan)) {
      history.push(`${RouteConstants.upgradePlan.url}`);
    } else {
      let { portal_url } = await Api.fetchStripePortalURL();
      window.open(portal_url, "_self");
    }
  };

  const executionsWidth =
    (quotas?.executions?.current_usage / quotas?.executions?.limit) * 100;
  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse} width="255px">
          <SidebarHeader>
            <div className="logotext">
              <div onClick={() => history.push(`/workflows/my-workflows`)}>
                {menuCollapse ? (
                  <img
                    src={FylamyntLogo}
                    alt="iconCheck"
                    width={30}
                    height={30}
                    className="logo-small"
                  />
                ) : (
                  <img
                    src={FylamyntTextLogo}
                    alt="iconCheck"
                    width={120}
                    height={120}
                    className="logo-big"
                  />
                )}
              </div>
            </div>
          </SidebarHeader>
          <SidebarContent id="content">
            <Menu iconShape="round">
              {Object.keys(menus).map((key, idx) => {
                const menu = menus[key];
                return (
                  <MenuItem
                    key={key}
                    data-for={menu.display}
                    data-tip
                    active={active === menu.baseUrl}
                    icon={
                      <img
                        src={require(`@assets/images/icons/icon-${menu.icon}.svg`)}
                        alt="iconCheck"
                        width={15}
                        height={15}
                      />
                    }
                  >
                    {menuCollapse && (
                      <ReactTooltip
                        id={menu.display}
                        place="top"
                        effect="solid"
                        backgroundColor="#3d3d3d"
                      >
                        {menu.display}
                      </ReactTooltip>
                    )}
                    {!menuCollapse && menu.display} <Link to={menu.url} />
                    {menu.baseUrl === "settings" &&
                      isAccountDetailFetched &&
                      !userDefaultAccounts.length && (
                        <img
                          src={warningSign}
                          alt="warningSign"
                          width="20"
                          height="20"
                          className="ml-2"
                        />
                      )}
                  </MenuItem>
                );
              })}
            </Menu>
            {!menuCollapse && (
              <>
                <div className="divider-wrap">
                  <div className="divider"></div>
                </div>
                <ul className="nav-menu">
                  {Object.keys(supportMenus).map((key, idx) => {
                    const menu = supportMenus[key];
                    return (
                      <li
                        key={idx}
                        className={`nav-menu__item ${
                          menu.baseUrl === active
                            ? "w--current"
                            : "w--not-selected"
                        } ${menu.className} icon__support`}
                      >
                        {menu?.internalLink ? (
                          <div
                            className="nav-link nav-link-support"
                            onClick={() => history.push(menu.url)}
                          >
                            {menu.display}
                          </div>
                        ) : (
                          <>
                            <a
                              href={menu.url}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="nav-link nav-link-support"
                            >
                              {menu.display}
                            </a>
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="divider-wrap">
                  <div className="divider"></div>
                </div>
                <div className="current-plan__info">
                  <div className="current-plan__details">
                    <div>
                      {quotas?.current_tier
                        ? capitalize(quotas?.current_tier)
                        : "NA"}
                    </div>
                    <div>
                      {quotas?.executions?.current_usage} /{" "}
                      {quotas?.executions?.limit} Runs
                    </div>
                  </div>
                  <div className="current-plan__bar-wrap">
                    <div
                      className={`current-plan__bar-wrap__bar-use ${
                        executionsWidth === 100 && "red"
                      }`}
                      style={{
                        width: `${executionsWidth}%`,
                      }}
                    ></div>
                  </div>
                  <div
                    className="current-plan__upgrade-link"
                    onClick={managePlan}
                  >
                    {INTERNAL_PLANS.includes(quotas?.current_tier)
                      ? "Upgrade Plan"
                      : "Manage Plan"}
                  </div>
                </div>
                <Version />
              </>
            )}
          </SidebarContent>
          <SidebarFooter id="footer">
            <Menu>
              <MenuItem
                icon={
                  <Avatar
                    name={user.name}
                    size="40"
                    maxInitials={2}
                    round={true}
                    style={{
                      marginBottom: "1rem",
                      cursor: "pointer",
                    }}
                    className="avtarBackground"
                    onClick={() => history.push("/user-profile/information")}
                  />
                }
              >
                <Link to="/user-profile/information">
                  {!menuCollapse && (
                    <div className="user-details pointer">
                      <div className="username">
                        {user?.name && !!user.name
                          ? user.name
                          : user?.email &&
                            user?.email
                              .split("@")[0]
                              .substring(0, 1)
                              .toUpperCase() +
                              user?.email.split("@")[0].substring(1)}
                      </div>
                      <div className="email" data-for="user-email" data-tip>
                        {user?.email}
                      </div>
                      <ReactTooltip
                        id="user-email"
                        place="top"
                        effect="solid"
                        backgroundColor="#3d3d3d"
                      >
                        {user?.email}
                      </ReactTooltip>
                    </div>
                  )}
                </Link>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
        <div
          className="open-close"
          style={{ borderColor: "rgb(46, 46, 46)" }}
          onClick={menuIconClick}
        >
          <div className="line-open-primary"></div>
          <div className="line-open-primary line-open-secondary"></div>
        </div>
      </div>
    </>
  );
};

export default LeftMenu;
