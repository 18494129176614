import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Confirm, FTNotification } from "@components/ui";
import { DataTable } from "@components/ui/data-table";
import { SettingsPanelHeader } from "../../settings-panel-components";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  fetchTerraformModule,
  removeTerraformModule,
} from "@redux/actions/terraformCLI.actions";
import tableColumns from "./tableColumns";
import Api from "@lib/api/api";
import ManageTerraformCLI from "./ManageTerraformCLI";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

type TerraformCLIProps = {};

const terraformInitialValues = {
  name: "",
  root: "",
  alias: "",
  selectedOption: "", // Git Repo or S3 Bucket
  source: "", // Selected item from either Git dropdown or S3 dropdown
};

const TerraformCLI: React.FC<TerraformCLIProps> = () => {
  // State
  const terraformCLIList = useSelector(
    (state: RootStateOrAny) => state.TerraformCLIReducer.terraformModules,
  );
  const [selectedTerraformModule, setSelectedTerraformModule] = useState(
    terraformInitialValues,
  );
  const [editMode, setEditMode] = useState("create");
  const [showTerraformCLIModal, setShowTerraformCLIModal] = useState(false);

  // Redux
  const showConfirm = useSelector(
    (state: RootStateOrAny) => state.uiReducer.showConfirm,
  );
  const loadingMessage = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.message,
  );

  const dispatch = useDispatch();

  const toggleTerraformCLIFormVisibility = mode => {
    setShowTerraformCLIModal(true);
    if (mode === "create") {
      setSelectedTerraformModule(terraformInitialValues);
      setEditMode(mode);
    }
  };

  const onEdit = async row => {
    let values = { ...row, selectedOption: "", source: "" };
    await setSelectedTerraformModule(values);
    await setEditMode("edit");
    toggleTerraformCLIFormVisibility("edit");
  };

  const onRemove = row => {
    setSelectedTerraformModule(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const columns = tableColumns(onEdit, onRemove);

  // useEffect Calls
  useEffect(() => {
    dispatch(fetchTerraformModule());
  }, [dispatch]);

  return (
    <>
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="The entry for this Terraform Module will be deleted."
          confirmValue={
            selectedTerraformModule ? selectedTerraformModule.name : ""
          }
          confirmRequiredText={`Confirm by typing '${
            selectedTerraformModule ? selectedTerraformModule.name : ""
          }' below`}
          verifiedCallback={async () => {
            dispatch(isFetching(true, "Deleting Terraform Module"));
            try {
              dispatch(toggleDeleteConfirmBox(false));
              let { name, alias } = selectedTerraformModule;
              await Api.deleteTerraformCLIModule(name, alias);
              dispatch(removeTerraformModule(selectedTerraformModule));
              FTNotification.success({
                title: "Terraform Module Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete Terraform Module",
                message: (error as any)?.message || "",
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}
      {showTerraformCLIModal && (
        <ManageTerraformCLI
          selectedTerraformModule={selectedTerraformModule}
          editMode={editMode}
          closeForm={setShowTerraformCLIModal}
          terraformCLIList={terraformCLIList}
        />
      )}
      <div className="d-flex flex-column">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="terraform-cli-modules"
          heading="Terraform Modules"
          breadCrumbText="Settings/Terraform Modules"
          toggleTerraformCLIFormVisibility={toggleTerraformCLIFormVisibility}
        />
        <div className="mt-40-px ml-2 gr-list-container table-overflow-list">
          {!loadingMessage &&
            (Array.isArray(terraformCLIList) && terraformCLIList.length > 0 ? (
              <DataTable
                className="snippet-list-table"
                columns={columns}
                data={terraformCLIList}
                responsive={true}
                fixedHeader={true}
              />
            ) : (
              <NoResultFound
                message="Add your first Terraform Module"
                detail="To add Terraform Module, click the 'Add New' button in the top-right of this screen."
                className="mt-100-px"
                errImgSrc="settings-es"
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default TerraformCLI;
