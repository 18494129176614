import React, { useEffect, useState } from "react";
import "./ForgotPassword.scss";
import { Formik } from "formik";
import Auth from "@aws-amplify/auth";
import { getValidationSchema } from "./utils/validationSchema";
import { SetPassword, SetUserName } from "./utils/FieldGetterSetter";
import FormSubmitError from "../utils/FormSubmitError";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Wait from "@components/ui/wait";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { loginSuccess } from "@redux/actions/user.actions";
import { useHistory } from "react-router-dom";
import LogoHeader from "@components/shared/LogoHeader/LogoHeader";

type ForgotPasswordProps = {};

const initialValues = {
  email: "",
  activationCode: "",
  password: "",
  confirmPassword: "",
};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [showAuthCode, setShowAuthCode] = useState(false);
  const validationSchema = getValidationSchema(showAuthCode);
  const [showFormSubmitError, setShowFormSubmitError] = useState(false);
  const toggleFormSubmitError = () =>
    setShowFormSubmitError(!showFormSubmitError);
  const dispatch = useDispatch();
  const loadingMessage = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.message,
  );
  const history = useHistory();
  const isLoggedIn = useSelector(
    (state: RootStateOrAny) => state.userReducer.isLoggedIn,
  );

  useEffect(() => {
    isLoggedIn && history.push("/workflows");
  }, [isLoggedIn, history]);

  const submitHandle = async (user, actions) => {
    actions.setSubmitting(true);
    if (showAuthCode) {
      dispatch(isFetching(true, "Saving and Signing In"));
      try {
        await Auth.forgotPasswordSubmit(
          user.email,
          user.activationCode,
          user.password,
        );
        setShowFormSubmitError(false);
        try {
          let authData = await Auth.signIn(user.email, user.password);
          setShowFormSubmitError(false);
          dispatch(loginSuccess(authData));
        } catch (error) {
          dispatch(isFetching(false, ""));
          setShowFormSubmitError(true);
          actions.setStatus({
            formSubmitMessage: "Your email or password don't match",
          });
        }
      } catch (error) {
        actions.setStatus({
          formSubmitMessage: (error as any).message,
        });
        dispatch(isFetching(false, ""));
        setShowFormSubmitError(true);
      }
    } else {
      actions.setTouched({});
      dispatch(isFetching(true, "Sending Confirmation Code"));
      try {
        await Auth.forgotPassword(user.email);
        dispatch(isFetching(false, ""));
        setShowFormSubmitError(false);
        setShowAuthCode(true);
      } catch (error) {
        actions.setStatus({
          formSubmitMessage: "That email doesn't belong to an account",
        });
        dispatch(isFetching(false, ""));
        setShowFormSubmitError(true);
      }
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitHandle}
      enableReinitialize={true}
    >
      {formik => (
        <div className="fp-container">
          {loadingMessage && <Wait text={loadingMessage} />}
          <LogoHeader />
          <div className="fp-card">
            <h1 className="fp-card-header">
              {showAuthCode ? "Create New Password" : "Reset Password"}
            </h1>
            {showFormSubmitError && (
              <div className="mb-35-px">
                <FormSubmitError
                  errorMsg={formik.status?.formSubmitMessage || ""}
                  toggleVisibility={toggleFormSubmitError}
                />
              </div>
            )}
            {showAuthCode ? (
              <SetPassword formik={formik} setShowAuthCode={setShowAuthCode} />
            ) : (
              <SetUserName formik={formik} />
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ForgotPassword;
