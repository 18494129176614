import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../settings-panel-components";

import { Confirm, FTNotification } from "@components/ui";
import ManageS3Bucket from "./ManageS3Bucket";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import { isFetching } from "@redux/actions/settingsPanel.action";
import Api from "@lib/api/api";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

type S3BucketsProps = {};

const S3Buckets: React.FC<S3BucketsProps> = () => {
  const dispatch = useDispatch();
  const showConfirm = useSelector(
    (state: RootStateOrAny) => state.uiReducer.showConfirm,
  );
  const [selectedS3Bucket, setSelectedS3Bucket] = useState(undefined);

  const [selectedS3BucketList, setSelectedS3BucketList] = useState([]);
  const [isFetchingS3BucketList, setIsFetchingS3BucketList] = useState(true);
  const [showManageS3BucketForm, setShowManageS3BucketForm] = useState(false);
  const aliasList = useSelector(
    (state: RootStateOrAny) => state.getStartedReducer.userDefaultAccounts,
  );

  const toggleS3BucketFormVisibility = () => {
    setShowManageS3BucketForm(true);
  };

  const onRemoveHandler = row => {
    setSelectedS3Bucket(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const s3Col = tableColumns(onRemoveHandler);

  /**
   * Loop over the list of target accounts(alias) to fetch
   * the list of s3 buckets selected corresponding to each
   * alias on Settings page
   */

  const fetchSelectedS3BucketList = useCallback(async () => {
    let promises = [];
    aliasList.forEach(alias => {
      promises.push(Api.fetchSelectedS3BucketList(alias.alias));
    });
    try {
      setIsFetchingS3BucketList(true);
      dispatch(isFetching(true, "Fetching S3 Buckets"));
      let response = await Promise.all(promises);
      let s3BucketList = [];
      response.forEach((res, i) => {
        let s3BucketListResponse =
          typeof res.body === "string" ? JSON.parse(res.body) : res.body;
        s3BucketList = s3BucketList.concat(
          s3BucketListResponse.map(obj => {
            obj["alias"] = aliasList[i]["alias"];
            return obj;
          }),
        );
      });
      dispatch(isFetching(false, ""));
      setSelectedS3BucketList(s3BucketList);
      setIsFetchingS3BucketList(false);
    } catch (e) {
      dispatch(isFetching(false, ""));
      setIsFetchingS3BucketList(false);
      console.log(e);
    }
  }, [aliasList, dispatch]);

  useEffect(() => {
    fetchSelectedS3BucketList();
  }, [fetchSelectedS3BucketList]);

  return (
    <>
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="The entry for this S3 bucket will be deleted."
          confirmValue={selectedS3Bucket ? selectedS3Bucket.Name : ""}
          confirmRequiredText={`Confirm by typing '${
            selectedS3Bucket ? selectedS3Bucket.Name : ""
          }' below`}
          verifiedCallback={async () => {
            dispatch(isFetching(true, "Deleting S3 Bucket"));
            try {
              dispatch(toggleDeleteConfirmBox(false));
              await Api.removeS3Bucket(
                selectedS3Bucket?.alias,
                selectedS3Bucket?.Name,
              );
              setSelectedS3BucketList(
                selectedS3BucketList.filter(
                  s3Bucket => s3Bucket.Name !== selectedS3Bucket.Name,
                ),
              );
              FTNotification.success({
                title: "S3 Bucket Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete S3 Bucket",
                message: (error as any).message,
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}
      {showManageS3BucketForm && (
        <ManageS3Bucket
          closeForm={setShowManageS3BucketForm}
          fetchSelectedS3BucketList={fetchSelectedS3BucketList}
        />
      )}
      <div className="d-flex flex-column">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="s3-buckets"
          heading="S3 Buckets"
          breadCrumbText="Settings/S3 Buckets"
          toggleS3BucketFormVisibility={toggleS3BucketFormVisibility}
        />
        {!isFetchingS3BucketList &&
          (selectedS3BucketList.length > 0 ? (
            <div className="mt-40-px ml-2 gr-list-container table-overflow-list">
              <DataTable
                className="snippet-list-table"
                columns={s3Col}
                data={selectedS3BucketList}
                responsive={true}
                fixedHeader={true}
              />
            </div>
          ) : (
            <NoResultFound
              message="Add your first S3 Bucket"
              detail="To add S3 Bucket, click the 'Add New' button in the top-right of this screen."
              className="mt-100-px"
              errImgSrc="settings-es"
            />
          ))}
      </div>
    </>
  );
};

export default S3Buckets;
