import React from "react";
import { Link } from "react-router-dom";
import { Consumer } from "../execution-details-lib/execution-details.context";
import * as moment from "moment";
import {
  dateTimeDisplayFormat,
  dateTimeOnHoverDisplayFormat,
} from "@lib/utils/constants";
import OnHover from "@components/ui/OnHover";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

export default class Details extends React.Component {
  render() {
    return (
      <Consumer>
        {({ executionDetails }) => (
          <React.Fragment>
            {executionDetails && (
              <div className="executiond-details execution-details-wrap">
                Details
                <div className="execution-details-block-wrap">
                  <div className="execution-details-block-div">
                    <div className="meta-wrap">
                      <div className="meta-title">Execution ID</div>
                      <div className="meta-text">{this.props.executionId}</div>
                    </div>
                    <div className="meta-wrap">
                      <div className="meta-title">Workflow</div>
                      <div className="meta-text">
                        {executionDetails.WorkflowDeleted ? (
                          <span>
                            {" "}
                            {replaceAllSpecialCharWithSpace(
                              executionDetails.DocumentName,
                            )}
                          </span>
                        ) : (
                          <Link
                            to={`/workflow/${executionDetails.DocumentName}/parameters`}
                          >
                            {replaceAllSpecialCharWithSpace(
                              executionDetails.DocumentName,
                            )}
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="meta-wrap">
                      <div className="meta-title">Version</div>
                      <div className="meta-text">
                        {executionDetails.DocumentVersion}
                      </div>
                    </div>
                  </div>
                  <div className="details-block">
                    <div className="meta-wrap">
                      <div className="meta-title">Trigger</div>
                      <div className="meta-text">
                        {executionDetails.Trigger}
                      </div>
                    </div>
                    <div className="meta-wrap">
                      <div className="meta-title">Start Time</div>
                      <div className="meta-text">
                        {!!executionDetails.ExecutionStartTime && (
                          <OnHover
                            title={moment(
                              executionDetails.ExecutionStartTime,
                            ).format(dateTimeOnHoverDisplayFormat)}
                          >
                            {moment(executionDetails.ExecutionStartTime).format(
                              dateTimeDisplayFormat,
                            )}
                          </OnHover>
                        )}
                      </div>
                    </div>
                    <div className="meta-wrap">
                      <div className="meta-title">End Time</div>
                      <div className="meta-text">
                        {(executionDetails.AutomationExecutionStatus ===
                          "Success" ||
                          executionDetails.AutomationExecutionStatus ===
                            "Cancelled" ||
                          executionDetails.AutomationExecutionStatus ===
                            "Failed" ||
                          executionDetails.AutomationExecutionStatus ===
                            "TimedOut") && (
                          <OnHover
                            title={moment(
                              executionDetails.ExecutionEndTime,
                            ).format(dateTimeOnHoverDisplayFormat)}
                          >
                            {moment(executionDetails.ExecutionEndTime).format(
                              dateTimeDisplayFormat,
                            )}
                          </OnHover>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
