import React, { useState, useEffect, useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { FilterableMultiSelect } from "carbon-components-react";
import { DataTable, FTNotification } from "@components/ui";

// Utils
import tableColumns from "./utils/tableColumns";
import { cleanData } from "./utils/utils";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
} from "@redux/actions/common.actions";
import { checkEquality, isEmpty } from "@lib/utils";
import Api from "@lib/api";

// Style
import "./EnvironmentHealth.scss";

// Assets
import ArrowRightIcon from "@assets/images/icons/icon-arrow-right.svg";
import RefreshIcon from "@assets/images/icons/icon-refresh.svg";

const EnvironmentHealth = () => {
  // Fetch data from persisted store.

  const environmentHealthRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer.recordsPerPage.environmentHealthRecords,
  );
  const environmentHealthCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer.currentPages.environmentHealthCPage,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [services, setServices] = useState([]);
  const [loadedParams, setLoadedParams] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [servicesList, setServicesList] = useState([]);

  const dispatch = useDispatch();
  const columns = tableColumns();

  const fetchEnvHealthData = useCallback(async () => {
    try {
      const response = await Api.getEnvHealthData();
      const filteredData = cleanData(response);
      setServiceData(filteredData);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchEnvHealthParams = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await Api.getEnvHealthParams();
      if (response?.region && response?.services) {
        setRegion(response.region);
        setServices([...response.services]);
        setLoadedParams(response);
        // Fetch latest Environment Health data
        fetchEnvHealthData();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, [fetchEnvHealthData]);

  const fetchRegionList = useCallback(async () => {
    try {
      let response = await Api.fetchEc2Regions();
      let regionList =
        typeof response === "string" ? JSON.parse(response) : response;
      setRegionList(regionList);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchServicesList = useCallback(async () => {
    try {
      let response = await Api.getAWSServicesSubset();
      let servicesList =
        typeof response === "string"
          ? JSON.parse(response)?.services
          : response?.services;
      setServicesList(servicesList);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const setEnvHealthParams = useCallback(
    async payload => {
      try {
        if (
          !payload.region ||
          !Array.isArray(payload.services) ||
          !payload.services.length
        ) {
          FTNotification.error({
            title: "Could not update",
            message: "Please select the Region & Service(s)",
          });
          return;
        }
        const response = await Api.setEnvHealthParams(payload);
        if (!isEmpty(response)) {
          // Fetch latest env health data
          setLoadedParams(response);
          fetchEnvHealthData();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [fetchEnvHealthData],
  );

  const detectParamsChange = () => {
    if (loadedParams?.region !== region) {
      return true;
    }
    if (!checkEquality(services, loadedParams?.services)) {
      return true;
    }
    return false;
  };

  const changeEnvironmentHealthRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "environmentHealthRecords",
        cPageRecords: rows,
      }),
    );
  };

  const changeEnvironmentHealthCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "environmentHealthCPage",
        cPage: cPage,
      }),
    );
  };

  useEffect(() => {
    fetchRegionList();
    fetchServicesList();
  }, [fetchRegionList, fetchServicesList]);

  // On component mount fetch env health params
  useEffect(() => {
    fetchEnvHealthParams();
  }, [fetchEnvHealthParams]);

  return (
    <>
      {!isLoading && (
        <div className="env-health-selector">
          <div>
            <label className="label mb-10-px">Region</label>
            <ReactSelect
              id="region"
              name="region"
              value={{
                value: region,
                label: region,
              }}
              handleChange={data => {
                if (data === null) {
                  setRegion("");
                } else if (!isEmpty(data) && !!data.value && !!data.label) {
                  setRegion(data.value);
                }
              }}
              isClearable
              isSearchable
              className="env-health-region"
              customControlClass="env-health-region-cc"
              customMenuClass="env-health-region-menu"
              customOptionClass="env-health-region-option"
              selectOptions={(regionList || []).map(item => ({
                value: item?.RegionName,
                label: item?.RegionName,
              }))}
            />
          </div>
          <div>
            <img
              className="env-health-arrow-right"
              src={ArrowRightIcon}
              alt="Arrow Right"
            />
          </div>
          <div>
            <label className="label mb-10-px">Service</label>
            <FilterableMultiSelect
              ariaLabel="MultiSelect"
              default
              id="service"
              placeholder="Search Services"
              onChange={({ selectedItems }) => {
                const selectedServices = selectedItems.map(item => item.value);
                setServices(selectedServices);
              }}
              className="env-health-multiselect"
              initialSelectedItems={(services || []).map(item => ({
                value: item,
                label: item,
              }))}
              items={(servicesList || []).map(item => ({
                value: item,
                label: item || "",
              }))}
              label="Service"
              itemToString={item => (item ? item.label : "")}
            />
          </div>

          <button
            className="env-health-btn-refresh"
            onClick={() => {
              setEnvHealthParams({ region, services });
            }}
            disabled={!detectParamsChange()}
            data-for="refresh"
            type="button"
            data-tip
          >
            <div className="env-health-refresh-wrap">Refresh</div>
            <img src={RefreshIcon} alt="Refresh data" height="16" />
          </button>
          <ReactTooltip id="refresh" effect="solid" type="light">
            Save new Region & Service(s)
          </ReactTooltip>
        </div>
      )}
      <div className="env-health-data-table">
        <DataTable
          columns={columns}
          data={serviceData}
          fixedHeader={true}
          fixedScrollHeight={"60px"}
          pagination={true}
          recordsPerPage={environmentHealthRecords}
          currentPage={environmentHealthCPage}
          onChangeRowsPerPage={changeEnvironmentHealthRowsPerPage}
          onChangeCurrentPage={changeEnvironmentHealthCurrentPage}
        />
      </div>
    </>
  );
};

export default EnvironmentHealth;
