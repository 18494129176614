import React, { useState } from "react";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import { useDispatch } from "react-redux";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { RouteConstants } from "../../../../routes/Constants";
import { FormFooter } from "@components/shared/GetStarted/Utils/Helpers";
import { Link } from "react-router-dom";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";

type ConnectorFormPropsModel = {
  alias: any;
  aliasList: Array<Object>;
  setAlias: (alias: string) => void;
};

const ConnectorForm: React.FC<ConnectorFormPropsModel> = ({
  alias = {},
  aliasList = [],
  setAlias,
}) => {
  /**
   * Redux connect props
   */

  const dispatch = useDispatch();

  /**
   * Application local state
   */

  const [redirect, setRedirect] = useState({
    doRedirect: false,
    redirectUrl: "",
  });

  /**
   * Sets state to navigate to settings page
   */
  const goBack = () => {
    setRedirect({
      doRedirect: true,
      redirectUrl: RouteConstants.eks.url,
    });
  };

  /**
   * This function handles formik submission
   * @param formInput Formik input data
   * @param actions formik actions object
   */
  const onSubmitHandle = async (formInput: any, actions: any) => {
    dispatch(isFetching(true, "Saving EKS Permission"));
    try {
      const payload = { name: formInput.name, arn: formInput.roleArn };

      await Api.saveEksActionRole(formInput.alias, payload);
      actions.setStatus({
        formSubmitMessage: "EKS Permission added successfully!",
      });

      // Navigate to settings page
      setTimeout(() => {
        goBack();
      }, 1500);
    } catch (error) {
      actions.setStatus({
        formSubmitMessage:
          (error as any)?.message || "Oops! Something went wrong",
      });
    }
  };

  return (
    <>
      {redirect.doRedirect && <Redirect to={redirect.redirectUrl} push />}
      {aliasList.length ? (
        <Formik
          initialValues={{
            alias: alias?.alias || "",
            roleArn: "",
            name: "",
          }}
          onSubmit={onSubmitHandle}
        >
          {formik => (
            <div className="d-flex flex-column">
              <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
              <div className="gs-panel-right-field-wrapper">
                <label className="label">Select Target Account</label>
                <ReactSelect
                  id="container-alias"
                  name="container-alias"
                  value={{
                    value: formik.values.alias,
                    label: formik.values.alias,
                  }}
                  handleChange={data => {
                    if (data === null) {
                      setAlias("");
                      formik.setFieldValue("alias", "");
                    } else {
                      setAlias(data.value);
                      formik.setFieldValue("alias", data.value.alias);
                    }
                  }}
                  selectOptions={aliasList?.map((alias: any) => {
                    return {
                      value: alias,
                      label: alias.alias,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                  required
                />
              </div>
              <div className="gs-panel-right-field-wrapper">
                <FormInput
                  fieldName="Name:"
                  name="name"
                  id="name"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                />
              </div>
              <div className="gs-panel-right-field-wrapper">
                <FormInput
                  fieldName="Role ARN:"
                  name="roleArn"
                  id="roleArn"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                />
              </div>
              <FormFooter onSubmit={formik.handleSubmit} />
              <div
                className={`${
                  formik.status?.isError ? "input-feedback" : ""
                } pt-2`}
              >
                {formik.status?.formSubmitMessage}
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <label className="label">
          Please set up Target Account &nbsp;
          <Link className="instruction-link" to={RouteConstants.aws.url}>
            here.
          </Link>
        </label>
      )}
    </>
  );
};

export default ConnectorForm;
