import React from "react";
import { OverflowMenu } from "@components/ui";
import { getServiceName } from "./HelperFunctions";
import { getPriorityIcon } from "@lib/utils";

const tableColumns = (displayName, serviceList, onDelete, connector) => {
  return [
    {
      name: displayName,
      selector: "incidentId",
      grow: 1,
      cell: row => {
        return getServiceName(row?.incidentId, serviceList);
      },
    },
    {
      name: "Incident Type",
      selector: "taskTypeName",
      grow: 1,
    },
    {
      name: "Priority",
      grow: 0.5,
      selector: "priority",
      cell: row => {
        let priority = row?.[connector]?.priority || "P1";

        return (
          <>
            <img
              src={getPriorityIcon(priority)}
              alt="priority-icon"
              className="mr-5-px"
            ></img>
            <div className="table">{priority}</div>
          </>
        );
      },
    },
    {
      name: "Incident Assignee",
      selector: "assignee",
      grow: 1,
      cell: row => row?.assignee?.name,
    },
    {
      name: "",
      selector: "options",
      grow: 0.5,
      cell: row => (
        <OverflowMenu>
          <div className="button-delete" onMouseDown={() => onDelete(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
    },
  ];
};

export default tableColumns;
