import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button, DataTable, Confirm, FTNotification } from "@components/ui";
import cancelIcon from "@assets/images/icons/icon-close.svg";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import tableColumns from "./tableColumn";
import { getTaskTypeAssignment } from "@redux/actions/taskTypeAssignment.action";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
} from "@redux/actions/common.actions";
import ManageTaskTypeAssignment from "./ManageTaskTypeAssignment";

import Api from "@lib/api/api";
import { isEmpty, sortObject } from "@lib/utils";
import { getServiceName } from "./HelperFunctions";

import "./TaskTypeAssignment.scss";

type ComponentProps = {
  connector: string;
  toggleModal: boolean;
  isConnected: boolean;
  setToggleModal: (boolean) => void;
  setIsDeleteTaskTypeAssignmentOpen: (boolean) => void;
};

const TaskTypeAssignment: React.FC<ComponentProps> = ({
  connector,
  toggleModal,
  isConnected,
  setToggleModal,
  setIsDeleteTaskTypeAssignmentOpen,
}) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const isEditMode = new URLSearchParams(search).get("edit") ? true : false;
  const [selectedTaskTypeAssignment, setSelectedTaskTypeAssignment] = useState(
    null,
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [displayName, setDisplayName] = useState("Service");
  // eslint-disable-next-line
  const [inputType, setInputType] = useState("");

  // Redux
  const showConfirm = useSelector(
    (state: RootStateOrAny) => state.uiReducer.showConfirm,
  );
  const taskTypeAssignments = useSelector(
    (state: RootStateOrAny) =>
      state.TaskTypeAssignmentReducer.taskTypeAssignments,
  );
  const connectorSchema = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.currentNodeSchema,
  );

  const taskTypeAssignCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.taskTypeAssignCPage,
  );

  const taskTypeAssignRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.taskTypeAssignRecords,
  );

  const changeTaskTypeAssignCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "taskTypeAssignCPage",
        cPage: cPage,
      }),
    );
  };

  const changeTaskTypeAssignRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "taskTypeAssignRecords",
        cPageRecords: rows,
      }),
    );
  };

  const onDelete = row => {
    setSelectedTaskTypeAssignment(row);
    dispatch(toggleDeleteConfirmBox(true));
    setIsDeleteTaskTypeAssignmentOpen(true);
  };

  const columns = tableColumns(displayName, serviceList, onDelete, connector);

  /**
   * @function filterData Function to filter the data in th list
   * @param searchText Search text query
   */
  const filterData = useRef((searchTerm: string) => {});
  filterData.current = (searchText: string = "") => {
    let filteredData = taskTypeAssignments.filter(val => {
      if (!searchText) return true;
      const { incidentId, taskTypeName } = val;
      const service = incidentId && incidentId.toLowerCase();
      const type = taskTypeName && taskTypeName.toLowerCase();
      const query = searchText && searchText.toLowerCase();
      return (
        (service && service.includes(query)) || (type && type.includes(query))
      );
    });
    setFilteredData(filteredData);
  };

  const getDisplayName = alertType => {
    if (isEmpty(alertType)) return "";

    let keys = Object.keys(alertType);
    let nameArr = keys.map(item => ({
      name: alertType[item]?.display_name,
      order: alertType[item]?.order,
    }));

    nameArr.sort((a, b) => a.order - b.order);

    return nameArr.map(item => item.name).join(" | ");
  };

  const fetchServiceData = useRef(() => {});
  fetchServiceData.current = async () => {
    if (isEmpty(connectorSchema)) return;

    const { alert_type } = connectorSchema;
    let serviceData = {};

    setDisplayName(getDisplayName(alert_type));
    const alertKeys = Object.keys(alert_type) as any;
    if (alertKeys.length === 1) {
      const alertKeyObj = alert_type[alertKeys[0]];
      if (alertKeyObj?.api?.endpoint) {
        try {
          let response = await Api._getData(alertKeyObj.api.endpoint);
          let parsedData =
            typeof response === "string" ? JSON.parse(response) : response;
          let { selector } = alertKeyObj.api;
          // Case 1: Key Value pairs
          if (
            alertKeyObj?.api?.key &&
            !Array.isArray(parsedData[alertKeyObj?.api?.key])
          ) {
            serviceData = parsedData[alertKeyObj.api.key];
            let list = Object.keys(serviceData).map(item => {
              return {
                value: selector.value === "Key" ? item : serviceData[item],
                label: selector.name === "Key" ? item : serviceData[item],
              };
            });
            setServiceList(list);
          }
          // Case 2: List with key
          else if (alertKeyObj?.api?.key) {
            let list =
              selector &&
              parsedData[alertKeyObj?.api?.key].map(item => {
                return {
                  value: item[selector.value] || "",
                  label: item[selector.name] || "",
                };
              });
            setServiceList(list);
          }
          // Case 3: List without key
          else {
            let list =
              selector &&
              parsedData.map(item => {
                return {
                  value: item[selector.value] || "",
                  label: item[selector.name] || "",
                };
              });
            setServiceList(list);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredData(taskTypeAssignments);
    }
  }, [taskTypeAssignments, searchTerm]);

  /**
   * @function componentMount - Function to fetch task type assignment on page load
   */
  useEffect(() => {
    if (isEditMode) {
      dispatch(getTaskTypeAssignment(connector));
    }
  }, [dispatch, connector, isEditMode]);

  useEffect(() => {
    if (isConnected) {
      fetchServiceData.current();
    }
  }, [connectorSchema, isConnected]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex mt-20-px mb-50-px justify-content-between">
        <div className="d-flex justify-content-between w-50 align-items-center">
          {showConfirm && (
            <Confirm
              heading="Delete Assignment"
              cancelText="Cancel"
              confirmText="Delete Assignment"
              confirmValue={getServiceName(
                selectedTaskTypeAssignment.incidentId,
                serviceList,
              )}
              confirmRequiredText={`Confirm by typing '${getServiceName(
                selectedTaskTypeAssignment.incidentId,
                serviceList,
              )}' below`}
              message="This assignment will be deleted permanently. There will be no impact on existing incidents that have been created based on this assignment."
              verifiedCallback={async () => {
                try {
                  setIsDeleteTaskTypeAssignmentOpen(false);
                  dispatch(toggleDeleteConfirmBox(false));
                  let { TaskTypeId, incidentId } = selectedTaskTypeAssignment;
                  let payload = {};
                  payload["incident-id"] = incidentId.toString();
                  payload["service"] = connector;

                  await Api.deleteTaskTypeAssignment(TaskTypeId, payload);
                  dispatch(getTaskTypeAssignment(connector));

                  FTNotification.success({
                    title: "Incident Type Assignment Deleted Successfully!",
                  });
                } catch (error) {
                  FTNotification.error({
                    title: "Could not delete Incident Type Assignment",
                    message: (error as any)?.message || "",
                  });
                }
              }}
            />
          )}
          <div className="d-flex align-items-center">
            <input
              name="Search"
              placeholder="Search"
              className="text-input text-input__darker"
              autoComplete="new-password"
              value={searchTerm}
              onChange={event => setSearchTerm(event.target.value)}
              onKeyPress={event => {
                if (event.key === "Enter" || event.keyCode === 8) {
                  filterData.current(searchTerm);
                }
              }}
            />
            {searchTerm && (
              <button
                type="button"
                className="reset-button__darker"
                onClick={() => {
                  setSearchTerm("");
                }}
              >
                <img src={cancelIcon} alt="" />
              </button>
            )}
          </div>
        </div>
        <Button
          className="btn-new-type"
          buttonStyle="primary"
          size="medium"
          text="New Assignment"
          icon="icon-plus.svg"
          onClick={() => setToggleModal(true)}
        ></Button>
      </div>
      {toggleModal && (
        <ManageTaskTypeAssignment
          currentNodeSchema={sortObject(connectorSchema.alert_type, "order")}
          setToggleModal={setToggleModal}
          connector={connector}
        />
      )}
      <div className="table-overflow-list">
        <DataTable
          columns={columns}
          data={filteredData}
          fixedHeader={true}
          fixedScrollHeight={"60px"}
          pagination={true}
          currentPage={taskTypeAssignCPage}
          recordsPerPage={taskTypeAssignRecords}
          onChangeCurrentPage={changeTaskTypeAssignCurrentPage}
          onChangeRowsPerPage={changeTaskTypeAssignRowsPerPage}
        />
      </div>
    </div>
  );
};

export default TaskTypeAssignment;
