import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/ui";
import Auth from "@aws-amplify/auth";
import visibleIcon from "@assets/images/icons/eye-icon__allowed.svg";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";
import { FTNotification } from "@components/ui";
import Wait from "@components/ui/wait";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import PasswordStrength from "@components/authentication/utils/PasswordStrength";
import ReactTooltip from "react-tooltip";

const ChangePassword: React.FC = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const dispatch = useDispatch();
  const [user, setUser] = useState<object>(null);
  const [oldPasswordVisible, toggleOldPasswordVisibility] = useState<boolean>(
    false,
  );
  const [passwordVisible, togglePasswordVisibility] = useState<boolean>(false);
  const [confirmPasswordVisible, toggleConfirmPasswordVisible] = useState<
    boolean
  >(false);
  const [isPasswordStrong, setStrongPasswordFlag] = useState<boolean>(false);
  const loadingMessage = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.message,
  );

  const getUserInfo = async () => {
    const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });
    setUser(userInfo);
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Please enter current password"),
    newPassword: Yup.string().required("Please enter a new password"),
    confirmPassword: Yup.string()
      .required("Please enter confirm password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleSubmit = async (fields, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Saving user information"));
    try {
      await Auth.changePassword(
        user, // the Cognito User Object
        fields.oldPassword,
        fields.newPassword, // the new password
      );
      FTNotification.success({
        title: "Password Changed Successfully!",
      });
    } catch (e) {
      FTNotification.error({
        title: "Could not update password",
        message: (e as any).message,
      });
    }
    actions.setSubmitting(false);
    dispatch(isFetching(false, ""));
  };

  const changeOldPasswordVisibility = () =>
    toggleOldPasswordVisibility(!oldPasswordVisible);

  const changePasswordVisibility = () =>
    togglePasswordVisibility(!passwordVisible);

  const changeConfirmPasswordVisibility = () =>
    toggleConfirmPasswordVisible(!confirmPasswordVisible);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {formik => (
        <div className="d-flex up-info" id="tab-password-content">
          {loadingMessage && <Wait text={loadingMessage} />}
          <div className="up-info-card">
            <h2 className="pb-30-px">Update Password</h2>
            <div className="up-info-form-input">
              <label className="up-info-form-input-label">
                Current Password
              </label>
              <div className="d-flex">
                <input
                  type={oldPasswordVisible ? "text" : "password"}
                  id="oldPassword"
                  className={`text-input ${
                    formik.errors.oldPassword &&
                    formik.touched.oldPassword &&
                    "error"
                  }`}
                  aria-describedby="basic-addon1"
                  {...formik.getFieldProps("oldPassword")}
                  autoComplete="new-password"
                />
                <img
                  onClick={changeOldPasswordVisibility}
                  src={oldPasswordVisible ? visibleIcon : invisibleIcon}
                  alt="invisibleIcon"
                  className="password-eye-icon"
                  width="18"
                  height="18"
                />
              </div>

              {formik.errors.oldPassword && formik.touched.oldPassword ? (
                <div className="input-feedback">
                  {formik.errors.oldPassword}
                </div>
              ) : null}
            </div>
            <div className="up-info-form-input">
              <label className="up-info-form-input-label">New Password</label>
              <div className="d-flex flex-column">
                <div className="d-flex w-a">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="newPassword"
                    className={`text-input ${
                      formik.errors.newPassword &&
                      formik.touched.newPassword &&
                      "error"
                    }`}
                    aria-describedby="basic-addon1"
                    {...formik.getFieldProps("newPassword")}
                    autoComplete="new-password"
                  />
                  <img
                    onClick={changePasswordVisibility}
                    src={passwordVisible ? visibleIcon : invisibleIcon}
                    alt="invisibleIcon"
                    className="password-eye-icon"
                    width="18"
                    height="18"
                  />
                </div>
                <div className="pwd-strength-wrapper">
                  {!!formik.values.newPassword.length && (
                    <PasswordStrength
                      password={formik.values.newPassword}
                      setPasswordScore={setStrongPasswordFlag}
                    />
                  )}
                </div>
              </div>

              {formik.errors.newPassword && formik.touched.newPassword ? (
                <div className="input-feedback">
                  {formik.errors.newPassword}
                </div>
              ) : null}
            </div>

            <div className="up-info-form-input">
              <label className="up-info-form-input-label">
                Confirm Password
              </label>
              <div className="d-flex">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  id="confirmPassword"
                  className={`text-input ${
                    formik.errors.confirmPassword &&
                    formik.touched.confirmPassword &&
                    "error"
                  }`}
                  aria-describedby="basic-addon1"
                  {...formik.getFieldProps("confirmPassword")}
                  autoComplete="new-password"
                />
                <img
                  onClick={changeConfirmPasswordVisibility}
                  src={confirmPasswordVisible ? visibleIcon : invisibleIcon}
                  alt="invisibleIcon"
                  className="password-eye-icon"
                  width="18"
                  height="18"
                />
              </div>

              {formik.errors.confirmPassword &&
              formik.touched.confirmPassword ? (
                <div className="input-feedback">
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>

            <div
              className="form-footer form-footer-submit"
              data-for="submitTip"
              data-tip
            >
              <Button
                text="Update Password"
                buttonStyle="primary"
                size="large"
                onClick={formik.handleSubmit}
                className="up-info-form-submit-btn"
                disabled={
                  !isPasswordStrong ||
                  formik.values.oldPassword === formik.values.newPassword
                }
              />
            </div>
            {(!formik.dirty ||
              !isPasswordStrong ||
              formik.values.oldPassword === formik.values.newPassword) && (
              <ReactTooltip
                id="submitTip"
                place="top"
                effect="solid"
                type="light"
              >
                Nothing to update.
              </ReactTooltip>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ChangePassword;
