import React, { useState, useEffect, useRef } from "react";
import {
  saveDefaultAccount,
  updateMessage,
} from "@redux/actions/getStarted.actions";
import * as Yup from "yup";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { useDispatch, useSelector } from "react-redux";
import Api from "@lib/api/api";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Formik } from "formik";
import { isEmpty } from "@lib/utils";
import { useHistory } from "react-router-dom";
import { v1 as uuidv1 } from "uuid";

const ConnectorForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountId = useSelector(
    state => state.appReducer.appConfig.awsAccountId,
  );
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  const isFirstUser = isAccountDetailFetched && !userDefaultAccounts.length;
  const [isDefault, setIsDefault] = useState(false);
  const [ec2, setEc2] = useState({ status: "", list: [] });
  const initialValues = {
    accountId: accountId,
    externalId: uuidv1(),
    assumedRole: "",
    alias: "",
    preferredRegion: "",
  };
  //methods
  const getEc2Regions = useRef(() => {});
  getEc2Regions.current = async () => {
    try {
      setEc2({ list: ec2.list, status: "loading" });
      const response = await Api.fetchEc2Regions();
      setEc2({ list: response, status: "success" });
    } catch (error) {
      console.error(error);
      setEc2({ list: [], status: "failed" });
    }
  };

  const validationSchema = Yup.object({
    accountId: Yup.string().required("Account ID is required"),
    externalId: Yup.string().default(""),
    assumedRole: Yup.string()
      .required("AssumeRole ARN is required")
      .matches(/^arn:aws:iam::\d{12}:role(\/[A-Za-z0-9]+)/, {
        message: "Invalid ARN",
      }),
    alias: Yup.string()
      .required("Alias is required")
      .min(3, "Alias name should have minimum 3 letters")
      .matches(/^\S*$/, { message: "Alias cannot have spaces" })
      .test("alias", "Duplicate Alias", value => {
        return userDefaultAccounts.length
          ? !userDefaultAccounts.filter(acc => acc.alias === value).length
          : true;
      }),
    preferredRegion: Yup.string()
      .required("Preferred Region is required")
      .default(""),
  });

  const buildJSON = formData => {
    return {
      alias: formData.alias,
      role_arn: formData.assumedRole,
      external_id: formData.externalId,
      preferred_region: formData.preferredRegion,
      is_default: isFirstUser ? true : isDefault,
    };
  };
  const formatData = formData => {
    let finalData = {};
    Object.keys(formData).forEach(val => {
      if (formData[val] !== "") {
        finalData[val] = formData[val];
      }
    });
    return finalData;
  };
  const addAccount = async (formData, actions) => {
    dispatch(updateMessage("Saving AWS Account"));
    const finalFormData = formatData(buildJSON(formData));
    try {
      const resp = await Api.saveNewAWSTargetAccount(finalFormData);
      dispatch(updateMessage(""));
      if (!isEmpty(resp?.ERROR) && resp?.ERROR?.isAxiosError) {
        actions.setStatus({
          formSubmitMessage: resp.ERROR?.response?.data?.message,
        });
      } else {
        dispatch(saveDefaultAccount(finalFormData));
        history.push("/settings/aws-settings");
      }
    } catch (error) {
      actions.setStatus({ formSubmitMessage: error.message });
    }
  };

  //effects
  useEffect(() => {
    if (ec2.status === "") {
      getEc2Regions.current();
    }
  }, [getEc2Regions, ec2.status]);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={addAccount}
      >
        {formik => (
          <div className="gs-panel-inner profile-right">
            <h2 className="pt-10-px pb-10-px mb-2">Add AWS Target Account</h2>
            <div className="profile-right-field-wrapper">
              <FormInput
                fieldName="Your Fylamynt Account ID:"
                name="accountId"
                id="accountId"
                fieldValue={formik.values.accountId}
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                className="copy-text"
                copyable
                readOnly
              />
            </div>
            <div className="profile-right-field-wrapper">
              <FormInput
                fieldName="External ID:"
                name="externalId"
                id="externalId"
                fieldValue={formik.values.externalId}
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                className="copy-text"
                copyable
                readOnly
              />
            </div>
            <div className="profile-right-field-wrapper">
              <FormInput
                fieldName="AssumeRole ARN:"
                name="assumedRole"
                id="assumedRole"
                fieldValue={formik.values.assumedRole}
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
                maxLength="256"
              />
            </div>
            <div className="profile-right-field-wrapper">
              <FormInput
                fieldName="Account Alias:"
                name="alias"
                id="alias"
                fieldValue={formik.values.alias}
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
                maxLength="256"
              />
            </div>
            <div className="profile-right-field-wrapper">
              {ec2.status === "loading" ? (
                <label className="label mb-0 s3-input-label loading-text mt-2">
                  <i>Loading EC2 Regions...</i>
                </label>
              ) : ec2.status === "success" ? (
                <div className="fi-item">
                  <label className="label">Select Preferred Region</label>
                  <ReactSelect
                    id="preferredRegion"
                    name="preferredRegion"
                    value={{
                      value: formik.values.preferredRegion,
                      label: formik.values.preferredRegion
                        ? formik.values.preferredRegion
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if (data === null) {
                        formik.setFieldValue("preferredRegion", "", formik);
                      } else if (!isEmpty(data) && !!data.value) {
                        formik.setFieldValue(
                          "preferredRegion",
                          data.value,
                          formik,
                        );
                      }
                    }}
                    selectOptions={ec2.list.map(r => {
                      return {
                        value: r.RegionName,
                        label: r.RegionName,
                      };
                    })}
                    customMenuClass="default-select-options-container"
                    customMenuListClass="default-select-options-list"
                    customValueContainerClass="default-select-value-container"
                    customControlClass="default-select-control"
                    customOptionClass="default-select-list-item"
                    required
                  />
                  {formik.errors["preferredRegion"] &&
                    formik.touched["preferredRegion"] && (
                      <div className="input-feedback">
                        {formik.errors["preferredRegion"]}
                      </div>
                    )}
                </div>
              ) : (
                <div className="input-feedback">
                  Error while fetching ec2 regions, Please contact the
                  administrator
                </div>
              )}
            </div>
            {!isFirstUser ? (
              <div className="d-flex">
                <input
                  type="checkbox"
                  className="pointer mr-2 default-checkbox"
                  checked={isDefault}
                  onChange={e => {
                    setIsDefault(!isDefault);
                  }}
                  id={`check-default-target-account`}
                />
                <label
                  className="label"
                  htmlFor={`check-default-target-account`}
                >
                  Set As Default
                </label>
              </div>
            ) : null}
            {!!formik.status && (
              <div className="input-feedback">
                {formik.status.formSubmitMessage}
              </div>
            )}
            <div onClick={formik.handleSubmit} className={`profile-submit-btn`}>
              Add Target Account
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ConnectorForm;
